import React, { useEffect, useState } from "react";
import { Center, Container, Stack, Text } from "@chakra-ui/layout";

export default function App() {
  const [data, setData] = useState();

  useEffect(() => {
    async function loadData() {
      const response = await fetch(
        "https://hello-api.mike-was-already-taken.com",
        {
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setData(response);
    }

    loadData();
  }, []);

  return (
    <Container>
      <Center axis="both">
        <Stack spacing={24}>
          <Text fontSize="xl">It's true. Mike was already taken.</Text>
          <Text fontSize="xl">{JSON.stringify(data)}</Text>
        </Stack>
      </Center>
    </Container>
  );
}
